export const tr_messages = {
    Dashboard: "Gösterge Tablosu",
    accaunt: "Hesap",
    access_right: "Erişim hakkı",
    access_rights: "Erişim hakları",
    actions: "Eylemler",
    active: "Aktif",
    active_patients: "Aktif hastalar",
    add: "Ekle",
    add_branch: "Şube ekle",
    add_bunk: "Ranza ekle",
    add_class: "Sınıf ekle",
    add_conditions: "Koşullar ekle",
    add_corps: "Bina ekle",
    add_department_user: "Bölüm kullanıcısı ekle",
    add_departmentes: "Bölüm ekle",
    add_document: "Belge ekle",
    add_drug_indicator: "İlaç reçetesi ekle",
    add_drug_indicator_warehouse: "İlaç reçetesi ekle (depo)",
    add_floor: "Kat ekle",
    add_hospital: "Hastane ekle",
    add_hospitalization_type: "Hastaneye yatış türü ekle",
    add_medicine: "İlaç ekle",
    add_movement: "Hareket ekle",
    add_nationality: "Uyruk ekle",
    add_new_bunk: "Yeni ranza ekle",
    add_new_department: "Yeni bölüm ekle",
    add_new_doctor_sign: "Yeni imza ekle",
    add_new_partner_clinic: "Yeni ortak klinik ekle",
    add_new_partner_doctor: "Yeni ortak doktor ekle",
    add_new_patient: "Yeni hasta ekle",
    add_new_service: "Yeni hizmet ekle",
    add_order_payment: "Sipariş ödemesi ekle",
    add_partner_clinic: "Ortak klinik ekle",
    add_partner_doctor: "Ortak doktor ekle",
    add_patient_indicators: "Hasta göstergeleri ekle",
    add_patient_physiotherapy: "Hastaya fizyoterapi ekle",
    add_physiotherapy: "Fizyoterapi ekle",
    add_processes: "Süreç ekle",
    add_purpose_of_hospitalization: "Hastaneye yatış amacını ekle",
    add_region: "Bölge ekle",
    add_requirement: "Gereksinim ekle",
    add_room: "Oda ekle",
    add_service: "Hizmet ekle",
    add_to_reception: "Randevu ekle",
    add_to_room: "Odaya ekle",
    add_transaction: "İşlem ekle",
    add_type_of_injury: "Yaralanma türü ekle",
    add_unit_of_measure: "Ölçü birimi ekle",
    address: "Adres",
    address_patient: "Hasta adresi",
    address_register: "Kayıt adresi",
    adds: "Ekle",
    adminPanel: "Yönetici paneli",
    age: "Yaş",
    agent: "Ajan",
    aggrement: "Anlaşma",
    all_conclusions: "Tüm sonuçlar",
    all_date: "Tarih",
    all_laboratory_conclusions: "Laboratuvar sonuçları",
    all_not_paid_price: "Toplam ödenmemiş tutar",
    all_service: "Tüm hizmetler",
    all_service_patient: "Hastaya ait tüm hizmetler",
    all_speciality: "Tüm uzmanlıklar",
    allergy: "Alerji",
    ambulatory: "Ayakta tedavi",
    amount: "Tutar",
    another_services: "Diğer hizmetler",
    another_services_income: "Diğer hizmetlerden gelir",
    archive: "Arşiv",
    arrival_date: "Varış tarihi",
    ask_about_write_off: "Silme hakkında sor?",
    authority: "Yetki",
    auto_write_off: "Otomatik silme",
    available: "Mevcut",
    back: "Geri",
    balance: "Bakiye",
    bank: "Banka",
    bank_account: "Banka hesabı",
    basic_information: "Temel bilgiler",
    bed_schedules: "Yatak planları",
    blank_head: "Boş başlık",
    blok: "Blok",
    blood: "Kan grubu",
    bonus: "Bonus",
    bonus_amount: "Bonus",
    bonus_amount_0: "Bonus miktarı",
    bonus_date: "Bonus tarihi",
    bonus_debt: "Kalan bakiye",
    bonus_mode: "Bonus oluşturma modu",
    bonus_paid_amount: "Ödenen miktar",
    bonuses: "Bonuslar",
    booked: "Rezerve edildi",
    born_place: "Doğum yeri",
    box_available: "Kutuda mevcut",
    box_quantity: "Kutudaki miktar",
    branch: "Şube",
    branches: "Şubeler",
    bunk: "Ranza",
    busy: "Dolu",
    buy_ready_product: "Ürün satın alma",
    c_sum: "sum",
    cabinat_balance: "Müşteri bakiyesi",
    cabinet: "Kabine",
    cach: "Nakit",
    cancel: "İptal",
    cannot_print: "Yazdırılamaz",
    cargo_storage: "Kargo deposu",
    cash: "Maaşlar",
    cash_amount: "Maaş miktarı",
    cashbox: "Kasa",
    cashbox_print: "Kasa yazdırma",
    category: "Kategori",
    category_template: "ICD kategorileri",
    category_or_service: "Kategori veya hizmet",
    category_services: "Hizmet kategorileri",
    change: "Değiştir",
    change_branch: "Şubeyi değiştir",
    change_write_off: "Silme değişikliği",
    choose_branch: "Bir şube seçin",
    choose_category: "Bir kategori seçin",
    choose_owner_branch: "Bir sahip şube seçin",
    choose_status: "Lütfen bir durum seçin",
    choose_template: "Bir şablon seçin",
    chose_a_speciality: "Bir uzmanlık seçin",
    cities: "Şehirler",
    citizenship: "Vatandaşlık",
    city: "Şehir",
    class: "Sınıf",
    clear: "Temizle",
    client: "Müşteri",
    client_name: "Müşteri adı",
    clients: "Müşteriler",
    clinic: "Klinik",
    clinic_diagnos: "Klinik tanı",
    close: "Kapat",
    closed_conclusions: "Onaylanmış sonuçlar",
    code: "Kod",
    code_id: "Kod ID",
    color: "Renk",
    comment: "Yorum",
    commentary: "Yorum",
    company_name: "Şirket adı",
    complated: "Tamamlandı",
    conResourcesText: "Kaynaklar altında",
    conclusion_date: "Sonuç tarihi",
    conclusions: "Sonuçlar",
    conclusions_history: "Sonuç geçmişi",
    condition: "Durum",
    condition_patient: "Hasta durumu",
    conditions: "Koşullar",
    confirm: "Onayla",
    confirmed: "Onaylandı",
    consultation_services: "Danışma hizmeti",
    consultation_services_income: "Danışma gelirleri",
    contact: "İletişim",
    content: "İçerik",
    contract_parent: "Ana sözleşme No.",
    contract_parnet: "Ana sözleşme No.",
    contract_payment: "Sözleşme ödemeleri",
    contract_service: "Sözleşme hizmetleri",
    contracts: "Sözleşmeler",
    contracts_n: "Sözleşme No.",
    contractual_basis: "Sözleşme esasına dayanarak",
    corps: "Bina",
    correspondent_account: "Muhabir hesap",
    cost_amount: "Maliyet tutarı",
    cost_order: "Sipariş maliyeti",
    count: "Sayı",
    counterparties: "Karşı taraflar",
    countries: "Ülkeler",
    country: "Ülke",
    country_code: "Ülke kodu",
    courier: "Kurye",
    couriers: "Kuryeler",
    create: "Oluştur",
    create_citizenship: "Vatandaşlık ekle",
    create_city: "Yeni şehir",
    create_conclusion: "Sonuç oluştur",
    create_condition: "Yeni durum",
    create_currency: "Yeni para birimi",
    create_hospitalization: "Hastaneye yatış ekle",
    create_module: "Modül oluştur",
    create_new_order: "Yeni sipariş oluştur",
    create_new_patient: "Yeni hasta oluştur",
    create_order_column: "Alan adı",
    create_organization: "Yeni organizasyon",
    create_task: "Görev oluştur",
    created: "Oluşturuldu",
    created_at: "Oluşturma tarihi",
    currency: "Para birimi",
    current_price: "Mevcut fiyat",
    daily: "Bugün için",
    dailyProcessReport: "Süreç raporu",
    dailyTreatment: "Günlük gösterge",
    dailyTreatment_patient: "Hasta günlük göstergeleri",
    daily_hospital: "Gündüz hastanesi",
    dapartment_user: "Bölüm kullanıcıları",
    dark: "Koyu",
    date: "Oluşturma tarihi",
    date_0: "Tarih",
    date_aggrement: "Anlaşma tarihi",
    date_hospitalization: "Hastaneye yatış tarihi",
    date_injury: "Hastalık tarihi",
    date_movement: "Hareket tarihi",
    date_of_issue: "Veriliş tarihi",
    date_produced: "Üretim tarihi",
    date_receiving: "Alım tarihi",
    date_register: "Kayıt tarihi",
    date_requirement: "Gereksinim tarihi",
    day: "Gün",
    day_hospital: "Gündüz hastanesi",
    day_hospital_for_month: "Bu ayki gündüz hastanesi",
    day_hospital_for_today: "Bugünkü gündüz hastanesi",
    day_hospital_for_week: "Bu haftaki gündüz hastanesi",
    day_hospital_income: "Gündüz hastanesi gelirleri",
    day_in_warehouse: "Depoda geçen günler",
    dds: "DDS",
    deal_amount: "Anlaşma tutarı",
    debt_type: "Borç türleri",
    debtors: "Borçlular",
    debts: "Borçlar",
    defects: "Kusurlar",
    delete: "Sil",
    delete_calcel: "Silme iptal edildi",
    delete_notification: "Bu işlem dosyayı kalıcı olarak silecek. Devam edilsin mi?",
    delete_notification_confirm: "Onu silmek istediğinizden emin misiniz?",
    delete_setting: "Sil",
    delete_success: "Silme tamamlandı",
    delivery_deadline: "Teslim tarihi",
    delivery_time: "Teslim süresi",
    department: "Bölüm",
    departure_date: "Ayrılış tarihi",
    description: "Açıklama",
    detail: "Detaylar",
    diagnos: "Ön tanı",
    direction: "Yön",
    disable: "Devre dışı bırak",
    disabled: "Devre dışı",
    discount: "İndirim",
    discount_cost: "İndirimli fiyat",
    discount_percent: "İndirim %",
    discount_price: "İndirim (Toplam)",
    discounts: "İndirimler",
    district: "Bölge",
    do_you_really_want_to_complete_the_hospitalization: "Hastaneye yatışı tamamlamak istiyor musunuz?",
    do_you_really_want_to_finish: "Bitirmek istediğinizden emin misiniz?",
    do_you_want: "Dikkat, bunu yapmak istediğinizden emin misiniz?",
    do_you_want_confirm: "Onaylamak istediğinizden emin misiniz?",
    dob: "Doğum tarihi",
    doctor: "Doktor",
    doctorProcessReport: "Genel süreç raporu",
    doctorSign: "İmza",
    doctorSigns: "İmzalar",
    doctor_0: "Doktor",
    doctor_bonuses: "Doktor bonusları",
    doctor_can: "Doktorunuza danışın",
    doctor_id: "Doktor ID",
    doctor_name: "Doktor (tam adı)",
    doctor_service: "Doktor hizmetleri",
    doctors_settings: "Doktor ayarları",
    conclusion_template_editor: "Sonuç şablonlarını düzenleyin",
    documents: "Belge",
    domain_name: "Alan adı",
    domen: "Alan",
    done_treatment: "Bitti (ilaç başarıyla uygulandı)",
    dont_have_file: "Dosya yok",
    download: "İndir",
    drive_license: "Sürücü belgesi",
    drug: "İlaç",
    drug_bad_reaction: "Hatalar (İlacın uygulanmasından sonra reaksiyon oluştu)",
    drug_good_reaction: "Devam et (Bu ilaçtan reaksiyon yok, prosedüre devam edin)",
    drug_indicator: "İlaç reçetesi",
    drug_indicator_warehouse: "İlaç reçetesi (depo)",
    drug_instruction: "İlaç / Talimatlar",
    duration: "Süre",
    dynamic_photo: "Dinamik duvar kağıdı",
    edit: "Düzenle",
    email: "E-posta",
    email_address: "E-posta adresi",
    employees_work_schedule: "Çalışanların iş programı",
    enable: "Etkinleştir",
    enabled: "Etkin",
    endPeriod: "Bitene kadar",
    end_date: "Bitiş tarihi",
    end_period: "Bitiş",
    enter_id: "Kimlik numarası girin",
    enumeration: "Listeleme",
    excelReport: "Excel raporu",
    exemple: "Örnek:",
    expence: "Gider",
    expences: "Giderler",
    expired: "Süresi dolmuş",
    express: "Ekspres",
    expresses: "Ekspres",
    fax: "Faks",
    female: "Kadın",
    file: "Dosya",
    file_upload: "Dosya yükle",
    file_uploaded: "Dosya yüklendi",
    filter: "Filtreler",
    filter_keyword: "Filtre anahtar kelimesi",
    final_conclusion: "Taburcu",
    fio: "Tam ad",
    first_name: "Adı",
    flat: "Daire",
    floor: "Kat",
    flow_types: "Gider türleri",
    footer_header: "Alt bilgi",
    footer_image: "Alt bilgi resmi",
    for_bussiness: "Tüzel kişi",
    for_outsource: "Dış kaynaklar için",
    for_personal: "Bireysel",
    form_template_and_signs: "Form şablonları ve imzalar",
    form_template_sign: "Form şablonu imzası",
    forms: "Formlar (ICD-10)",
    forms_mkb: "Formlar (ICD-10)",
    conclusion_templates: "Belge formları",
    free: "Ücretsiz",
    from: "Nereden",
    from_hours: "Saatten itibaren",
    from_time: "Başlangıç zamanı",
    from_whom: "Kimden",
    full_logo: "Tam logo",
    full_name: "Tam isim",
    gender: "Cinsiyet",
    gender_patients: "Hasta cinsiyeti",
    geography_settings: "Coğrafi ayarlar",
    glass: "Cam",
    go_back: "Geri dön",
    group: "Grup",
    group_of_patient: "Hasta yaş grupları",
    groups: "Gruplar",
    half_logo: "Kısmi logo",
    half_yearly: "Yarı yıllık",
    height: "Boy",
    hidden: "Belirtilmedi",
    history: "Tarihçe",
    history_transaction: "İşlem geçmişi",
    hospital: "Hastane",
    hospital_income: "Hastane gelirleri",
    hospital_patients_month: "Bu ay hastanedeki hastalar",
    hospital_patients_today: "Bugün hastanedeki hastalar",
    hospital_patients_week: "Bu hafta hastanedeki hastalar",
    hospitalization: "Hastaneye yatış",
    hospitalization_complated: "Hastaneye yatış tamamlandı",
    hospitalization_type: "Hastaneye yatış türü",
    hour: "Saat",
    house: "Ev",
    how_many_days: "Kaç gün",
    how_prepare_medicine: "İlaçlar nasıl hazırlanır",
    id_code: "Kimlik kodu",
    id_number: "Sipariş kimlik numarası",
    ifo: "Ad-Soyad",
    income_product: "Gelen ürünler",
    incoming: "Gelen",
    indicator: "Gösterge",
    indicators: "Göstergeler",
    info_aggrement: "Sözleşme bilgileri",
    info_treatment: "Tedavi bilgileri",
    inn: "Vergi kimlik numarası",
    input: "Giriş yap",
    input_order_id_for_delete_status: "Durumu sil",
    instruction_for_use: "Kullanım talimatları",
    insurance_company: "Sigorta şirketi",
    insurance_number: "Sigorta numarası",
    integration: "Entegrasyon",
    integration_price: "Entegrasyon fiyatı",
    invalid: "Hatalı",
    invoice: "Fatura",
    invoice_insurance: "Sigorta faturaları",
    invoices: "Faturalar",
    is_active: "Aktif",
    is_close: "Sonuç onaylandı",
    is_conclusion: "Sonuç",
    is_register: "Kayıt",
    is_report: "Tam rapor",
    lab_nurse: "Numune toplama",
    laborant: "Laborant",
    laboratory: "Laboratuvar",
    lack_of: "Eksik",
    last_login: "Son giriş",
    lasting: "Süre",
    legal_address: "Yasal adres",
    lend: "Ödünç",
    letterhead: "Ortak",
    life_time: "Ömür",
    lifetime: "Ömür",
    lifetime_services: "Ömür boyu hizmetler",
    light: "Açık",
    limit: "Limit",
    load: "Yükle",
    loading: "Yükleniyor",
    locked: "Kilitli",
    log_out: "Çıkış yap",
    login: "Giriş",
    logo: "Logo",
    main: "Ana",
    main_warehouses: "Ana depolar",
    male: "Erkek",
    man: "Erkek",
    manufactured_date: "Üretim tarihi",
    material: "Malzeme",
    materials_consuption: "Malzeme tüketimi",
    materials_consuptions: "Malzeme tüketimleri",
    measurement: "Ölçüm",
    measurement_full: "Ölçüm birimi",
    measurements: "Ölçümler",
    medical_history: "Tıbbi geçmiş",
    medicine: "Ürünler",
    medicines: "İlaçlar",
    medicines_on: "İlaçlar N/O",
    men: "Erkekler",
    mfo: "MFO",
    minut: "Dakika",
    modules: "Modüller",
    money_per_day: "Günlük fiyat",
    month: "Ay",
    monthly: "Aylık",
    movement: "Hareket",
    my_conclusions: "Benim sonuçlarım",
    my_informal_laboratory_patients: "Laboratuvar siparişleri",
    my_informal_patients: "Doktor siparişleri",
    my_laboratory: "Laboratuvarım",
    my_office: "Benim ofisim",
    my_patients: "Benim hastalarım",
    name: "Ad",
    name_0: "Ad",
    name_service: "Hizmet adı",
    name_template: "Şablon adı",
    nationality: "Milliyet",
    nds: "KDV",
    needed: "Gerekli",
    new: "Yeni",
    new_aggrement: "Yeni anlaşma",
    new_category_service: "Yeni hizmet kategorisi",
    new_client: "Yeni müşteri",
    new_country: "Yeni ülke",
    new_daily_process_report: "Yeni süreç raporu",
    new_discount: "Yeni indirim",
    new_expence: "Yeni gider",
    new_groups: "Yeni grup",
    new_measurements: "Yeni ölçü birimi",
    new_order: "Yeni sipariş",
    new_owner_clinic: "Yeni markalaşma",
    new_partner_clinic: "Yeni ortak klinik",
    new_partner_doctor: "Yeni ortak doktor",
    new_patient: "Hasta ekle",
    new_payment_type: "Yeni ödeme türü",
    new_photo: "Fotoğraf ekle",
    new_price: "Bir miktar için fiyat",
    new_product: "Yeni ürün",
    new_project: "Yeni proje",
    new_report: "Yeni rapor",
    new_role: "Yeni rol",
    new_room: "Yeni oda",
    new_service: "Yeni hizmet",
    new_source: "Yeni kaynak",
    new_specialities: "Yeni uzmanlık",
    new_status: "Yeni durum",
    new_supplier: "Yeni tedarikçi",
    new_system: "Yeni sistem",
    new_tag: "Yeni etiket",
    new_tariff: "Yeni tarife",
    new_task: "Yeni görev",
    new_time: "Yeni zaman",
    new_type_document: "Yeni belge türü",
    new_type_expence: "Yeni gider türü",
    new_user: "Yeni kullanıcı",
    new_warehouse: "Yeni depo",
    next: "Sonraki",
    no: "Hayır",
    normaCost: "Gider standardı",
    normaLaboratory: "Laboratuvar standardı",
    not_full: "Eksik",
    not_paid: "Ödenmedi",
    not_paid_invoices: "Ödenmeyen faturalar",
    not_paid_of_all: "Hiçbir şey ödenmedi",
    not_paid_sum: "Ödenmemiş tutar",
    not_set: "Belirtilmedi",
    note: "Not",
    notes: "Notlar",
    npt_active: "Pasif",
    number: "Numara",
    number_aggrement: "Anlaşma numarası",
    number_card: "Ayakta hasta kartı numarası",
    number_of_closed_conclusions_month: "Bu ay onaylanan sonuçlar",
    number_of_orders_month: "Bu ayki siparişler",
    number_of_orders_today: "Bugünkü siparişler",
    number_of_orders_total: "Toplam siparişler",
    number_of_patients_month: "Bu ayki hastalar",
    number_of_patients_today: "Bugünkü hastalar",
    number_of_patients_total: "Toplam hastalar",
    number_of_patients_week: "Bu haftaki hastalar",
    number_of_written_conclusions_month: "Bu ay yazılan sonuçlar",
    nurse_office: "Hemşire ofisi",
    nurse_wrong_choice: "Hemşire yanlış hücre seçti",
    oked: "OKED",
    okonx: "OKONH",
    old: "Eski",
    on_close: "Kapatırken",
    on_payment: "Ödemede",
    once_price: "Birim fiyat",
    onlis: "ONLIS",
    open: "Açık",
    operation_canceled: "Operasyon iptal edildi",
    order: "Sipariş",
    orderServiceTerm: "Siparişlerin tarihlere göre sıralaması",
    order_amount: "Hizmet miktarı",
    order_amput: "Hizmet tutarı",
    order_column: "Alan oluşturma",
    order_count: "Sipariş sayısı",
    order_created_at: "Sipariş alınma tarihi",
    order_date: "Sipariş tarihi",
    order_id: "Sipariş kimliği",
    order_not_found: "Sipariş bulunamadı",
    order_number: "Sipariş numarası",
    order_payment: "Sipariş ödemesi",
    order_service: "Sipariş hizmeti",
    order_updated_at: "Uygulama tarihi",
    orders: "Siparişler",
    organization: "Organizasyon",
    outgoing: "Giden",
    outpatient_card: "Ayakta hasta kartı",
    outsource: "Dış kaynak",
    outsource_add: "Dış kaynak ekle",
    outsource_party: "Dış kaynak partisi",
    outsourcing_files: "Dış kaynak dosyası",
    overpaid: "Fazla ödeme yapıldı",
    overpaid_amount: "Fazla miktar",
    own_doctor: "Kendi doktorumuz",
    owner_branch: "Şubeler",
    owner_clinic: "Markalaşma",
    package_quantity: "Paket miktarı",
    page_header: "Sayfa başlığı",
    paid: "Ödendi",
    paid_invoices: "Ödenen faturalar",
    paid_partner_bonus: "Ortaklara ödenen bonuslar",
    paid_price: "Ödenen miktar",
    parent: "Üst",
    parent_category: "Üst kategori",
    parent_service: "Üst hizmetler",
    partner_amount: "Ortak tutarı",
    partner_bonus: "Ortak bonusları",
    partner_clinic: "Ortak klinik",
    partner_clinic_full_logo: "Tam logo",
    partner_clinic_full_logo_active: "Tam logo aktif",
    partner_clinic_mini_logo: "Mini logo",
    partner_clinic_mini_logo_active: "Mini logo aktif",
    partner_clinics: "Ortak klinikler",
    partner_doctor: "Ortak doktor",
    partners: "Ortaklar",
    party_services: "Grup hizmetleri",
    passport_number: "Pasaport numarası",
    password: "Şifre",
    patient: "Hasta",
    patientResidenceReport: "Bölge raporu",
    patientServiceReport: "Hizmet raporu",
    patient_0: "Hasta",
    patient_age_reports: "Yaş raporları",
    patient_ages: "Hasta yaşları",
    patient_balance: "Hasta bakiyesi",
    patient_conclusions: "Hasta sonuçları",
    patient_histories: "Hasta geçmişleri",
    patient_history: "Hasta geçmişi",
    patient_hospitalization: "Hastanın hastaneye yatışı",
    patient_id: "Hasta kimliği",
    patient_indicator: "Hasta göstergesi",
    patient_indicators: "Hasta göstergeleri",
    patient_info: "Hasta bilgisi",
    patient_name: "Hasta adı",
    patient_or_fio: "Hasta No. veya Tam adı",
    patient_physiotherapy: "Hasta fizyoterapisi",
    patient_reject: "Hasta reddetti",
    patient_report: "Tüm hasta sonuçları",
    patient_service: "Hasta hizmetleri",
    patients: "Hastalar",
    patients2: "Hastalar",
    patients_count: "Hasta sayısı",
    patients_income: "Toplam klinik geliri",
    patronymic: "Baba adı",
    pay: "Öde",
    pay_order: "Siparişleri öde",
    payable_sum: "Ödenecek tutar",
    payment: "Ödeme",
    paymentBonusP: "Bonus ödemeleri",
    payment_report_limit: "Ödeme raporu limiti",
    payment_reports: "Ödeme raporları",
    payment_type: "Ödeme türü",
    payment_types: "Ödeme türleri",
    payments: "Ödemeler",
    payments_for_expenses: "Giderler için ödemeler",
    payments_report: "Ödemeler raporu",
    percent: "Satış %",
    percent_: "%",
    percentage: "Yüzde",
    period: "Dönem",
    phone: "Telefon",
    phone_number: "Telefon numarası",
    phone_number_is_not_valid: "Bu telefon numarası geçersiz",
    phone_numbers: "Telefon numaraları",
    phone_two: "Telefon 2",
    photo: "Fotoğraf (3x4 cm)",
    phy_office: "Fizyoterapi ofisi",
    physiotherapy: "Fizyoterapi",
    pieces: "adet",
    placeholder_time_date: "Tarih ve saat seçin",
    please_choose_parent: "Lütfen üst kategoriyi seçin",
    please_choose_role: "Lütfen bir rol seçin",
    please_enter_parol: "Lütfen şifreyi girin",
    pockets: "Cepler",
    position: "Pozisyon",
    pp: "P.P.",
    preparing: "Hazırlıkta",
    price: "Maliyet",
    price2: "Fiyat",
    price_aggrement: "Anlaşma tutarı",
    price_one_day_min_room_price: "Bir günlük en düşük ranza paketi fiyatı",
    price_one_day_with_room_price: "Bir günlük ranza dahil paket fiyatı",
    price_one_day_without_room_price: "Bir günlük paket fiyatı",
    price_per_day: "Günlük fiyat",
    price_with_room_price: "{days} gün için ranza dahil paket fiyatı",
    print: "Yazdır",
    print_audit: "Yazdır denetimi",
    print_center: "Yazdırma merkezi",
    print_conclusion: "Sonuç yazdır",
    print_double: "2x Yazdır",
    print_form: "Yazdırma formu",
    print_hitory: "Yazdırma geçmişi",
    print_insurance: "Tıbbi kart",
    print_qr: "QR kod yazdır",
    printing: "Yazdırma",
    procedural: "Prosedür",
    procedural_0: "Prosedür",
    procedural_list: "Prosedür listesi",
    process: "Süreç",
    process_l: "L.P.D süreçleri",
    processes: "Süreçler",
    procurement: "Tedarik",
    product: "Ürün",
    product_detail: "Ürün detayları",
    products: "Ürünler",
    profile: "Profil",
    project: "Proje",
    project_name: "Proje adı",
    projects: "Projeler",
    provision: "Koşul",
    puls: "Tansiyon",
    purchase_price: "Satın alma fiyatı",
    purchase_type: "Satın alma türü",
    purchasing_products: "Ürün satın alma",
    purpose_of_hospitalization: "Hastaneye yatış amacı",
    purpose_quantity: "Amaç miktarı",
    qty_come: "Gelen miktar",
    quantity: "Miktar",
    quantity_short: "Miktar",
    quarantine: "Karantina",
    quarterly: "Üç ayda bir",
    rate: "Kurs",
    raw_producement: "Hammadde tedariki",
    reason: "Sebep",
    receive: "Gelen",
    receive_: "Al",
    reception_visit: "Randevu ziyareti",
    recipient: "Alıcı",
    refresh: "Yenile",
    refund: "İade",
    region: "Bölge",
    reject: "Reddet",
    rejected: "Reddedildi",
    remaind: "Kalan",
    remainder: "Kalan",
    remainder_reports: "Prosedür raporu",
    remainders: "Kalanlar",
    replacement: "Değiştirme",
    report_clinics: "Klinik raporu",
    report_doctors: "Doktor raporu",
    report_insurance_company: "Sigorta şirketi raporu",
    report_partner_clinics: "Ortak klinikler raporu",
    report_partner_doctors: "Ortak doktorlar raporu",
    report_warehouse: "Sipariş raporu",
    reports: "Raporlar",
    requirement: "Gereksinim",
    requirements: "Gereksinimler",
    reset: "Sıfırla",
    resident_price: "Yerleşik olmayanlar için fiyat",
    resourcesText: "Kaynaklar",
    responsible_name: "Temsilci",
    return_material: "Malzeme iade et",
    reverse: "Ters",
    reverse_rate: "Ters oran",
    rkp_nds: "RKP KDV",
    role: "Rol",
    role_name: "Rol adı",
    roles: "Roller",
    room: "Oda",
    rooms: "Odalar",
    rooms_number: "Oda numarası",
    running_title: "Başlık",
    saldo: "Bakiye",
    sale_amount: "Satış miktarı",
    sale_percentage: "Satış yüzdesi",
    sale_price: "Satış fiyatı",
    save: "Kaydet",
    save_and_close: "Kaydet ve kapat",
    save_and_create_new: "Kaydet ve yeni ekle",
    save_and_go: "Kaydet ve devam et",
    schedule: "Program",
    schedule_cabinet: "Kabine programı",
    score: "Hesaplar",
    scores: "Hesaplar",
    search: "Ara",
    search_0: "Ara",
    search_doctor: "Doktor ara",
    search_order: "Siparişleri bul",
    search_order0: "Siparişi bul",
    search_template: "Şablon bul",
    secondary_doctor: "Yönlendiren doktor",
    select_patient: "Bir hasta seçin",
    select_patient_or_find: "Soldan bir hasta seçin veya yeni ekleyin",
    select_time: "Tarih ve saat seçin",
    send: "Gönder",
    sent: "Gönderildi",
    serial_number: "Seri numarası",
    series_and_number: "Seri ve numara",
    service: "Hizmet",
    service_category: "Hizmet kategorisi",
    service_categoy: "Hizmet kategorisi",
    service_name: "Hizmet adı",
    service_type: "Hizmet türü",
    service_type_id: "Hizmet kategorisi kimliği",
    service_type_name: "Hizmet türü adı",
    service_types: "Hizmet türleri",
    services: "Hizmetler",
    services_count: "Hizmet sayısı",
    set_header_footer: "Başlık ve altbilgiyi ayarla",
    set_sign: "İmza ayarla",
    set_transaction_amount: "İşlem miktarını belirtin",
    settings: "Ayarlar",
    short_name: "Kısa ad",
    show: "Gösterildi",
    show_0: "Göster",
    show_audit: "Denetimi göster",
    show_conclusions: "Sonuçları göster",
    show_drug: "İlaçları görüntüle",
    show_valid_price: "İlaç maliyetini belirtin",
    showing: "Görüntüle",
    sickNumber: "ICD kodu",
    sign_in: "Giriş yap",
    sku: "SKU",
    slug: "Benzersiz kimlik",
    social: "Kaynak",
    source: "Kaynak",
    specialities: "Uzmanlıklar",
    speciality: "Uzmanlık",
    speciality_doctor: "Doktor uzmanlığı",
    startPeriod: "Başlangıç tarihi",
    start_date: "Başlangıç tarihi",
    start_input_find_service: "Hizmet adı veya makale yazmaya başlayın",
    start_period: "Başlangıç",
    stasionary_cashier: "Hastane veznesi",
    static_info: "İstatistikler",
    stationary: "Hastane",
    stationary_settings: "Hastane ayarları",
    status: "Durumlar",
    status_0: "Durum",
    status_closed: "Başarılı",
    status_condition: "Koşul durumu",
    status_doctor: "Doktorda",
    status_doctor_free: "Doktor siparişleri",
    status_lab_nurse: "Numune toplama",
    status_laborant: "Laborantta",
    status_laborant_free: "Laboratuvar siparişleri",
    status_nurse: "Numune toplama",
    status_payment: "Ödemede",
    status_semi_created: "Onay bekliyor",
    status_waiting_paid: "Ödeme bekliyor",
    status_written: "Sonuç yazıldı",
    street: "Cadde",
    subject_of_agreement: "Anlaşmanın konusu (hizmetler)",
    sum: "Toplam",
    sum_expence: "Gider toplamı",
    sum_receive: "Gelir toplamı",
    summ: "Toplam",
    suppliers: "Tedarikçiler",
    suppliers_aggrement: "Tedarikçi anlaşmaları",
    surname: "Soyadı",
    symbol: "Simge",
    system_settings: "Sistem ayarları",
    systems: "Sistemler",
    tags: "Etiketler",
    tariff: "Tarife",
    tariffs: "Tarifeler",
    task_type: "Görev türü",
    task_types: "Görev türleri",
    tasks: "Görevler",
    tel_number: "Telefon numarası",
    temperature: "Sıcaklık",
    templates: "Şablonlar",
    term: "Son kullanma tarihi",
    term_aggrement: "Anlaşma süresi",
    terminal: "Terminal",
    test: "Test",
    theme_setting: "Tema ayarları",
    time: "Zaman",
    times: "Zaman",
    title: "Başlık",
    to_history: "Geçmişe",
    to_time: "Bitiş zamanı",
    today_transactions: "Bugünkü toplam",
    top_services: "En iyi hizmetler",
    total: "Toplam",
    total_0: "Toplam",
    total_amount: "Toplam miktar",
    total_cost: "Toplam maliyet",
    total_price: "Toplam tutar",
    total_reports: "Toplam raporlar",
    totals: "Toplamlar",
    totaly: "Genel",
    transactionScores: "Bakiye",
    transactions: "İşlemler",
    transmitter: "Gönderici",
    type: "Tür",
    type_condition: "Durum türü",
    type_documents: "Belge türü",
    type_expence: "Gider türü",
    type_expences: "Gider türleri",
    type_of_injury: "Yaralanma türü",
    type_phone: "Telefon türü",
    type_receive: "Gelen türleri",
    typebonus: "Bonus türü",
    unnecessary_enclosure: "Gereksiz sonuç",
    until: "Kadar",
    update: "Güncelle",
    update_access_rights: "Erişim haklarını güncelle",
    update_branch: "Şubeyi güncelle",
    update_bunk: "Ranzayı güncelle",
    update_category_service: "Hizmet kategorisini güncelle",
    update_citizenship: "Vatandaşlığı güncelle",
    update_city: "Şehri güncelle",
    update_class: "Sınıfı güncelle",
    update_client: "Müşteriyi güncelle",
    update_condition: "Koşulu güncelle",
    update_conditions: "Koşulları güncelle",
    update_corps: "Binayı güncelle",
    update_country: "Ülkeyi güncelle",
    update_currency: "Para birimini güncelle",
    update_date: "Tarihi güncelle",
    update_department_user: "Bölüm kullanıcısını güncelle",
    update_departmentes: "Bölümü güncelle",
    update_discount: "İndirimi güncelle",
    update_doctor_sign: "İmzayı güncelle",
    update_expence: "Gideri güncelle",
    update_floor: "Katı güncelle",
    update_hospitalization: "Hastaneye yatışı güncelle",
    update_hospitalization_type: "Hastaneye yatış türünü güncelle",
    update_module: "Modülü güncelle",
    update_movement: "Hareketi güncelle",
    update_nationality: "Uyruğu güncelle",
    update_new_patient: "Yeni hastayı güncelle",
    update_organization: "Kuruluşu güncelle",
    update_partner_clinic: "Ortak kliniği güncelle",
    update_partner_doctor: "Ortak doktoru güncelle",
    update_patient: "Hastayı güncelle",
    update_patient_indicators: "Hasta göstergelerini güncelle",
    update_payment_type: "Ödeme türünü güncelle",
    update_product: "Ürünü güncelle",
    update_project: "Projeyi güncelle",
    update_purpose_of_hospitalization: "Hastaneye yatış amacını güncelle",
    update_region: "Bölgeyi güncelle",
    update_report: "Raporu güncelle",
    update_role: "Rolü güncelle",
    update_room: "Odayı güncelle",
    update_service: "Hizmeti güncelle",
    update_setting: "Güncelle",
    update_source: "Kaynağı güncelle",
    update_specialities: "Uzmanlığı güncelle",
    update_system: "Sistemi güncelle",
    update_tags: "Etiketi güncelle",
    update_tariff: "Tarifeyi güncelle",
    update_task: "Görevi güncelle",
    update_teg: "Etiketi güncelle",
    update_time: "Zamanı güncelle",
    update_type_document: "Belge türünü güncelle",
    update_type_expence: "Gider türünü güncelle",
    update_type_of_injury: "Yaralanma türünü güncelle",
    used: "Kullanıldı",
    used_cassettes: "Kullanılmış kasetler",
    used_glasses: "Kullanılmış camlar",
    used_medicine: "Kullanılmış prosedür",
    user: "Kullanıcı",
    user_amount: "Kullanıcı sayısı",
    username: "Sahibi:",
    users: "Kullanıcılar",
    valid: "İlaçlar",
    validation: "Hata",
    version: "Sürüm",
    vial_quantity: "Flakon sayısı",
    view: "Görünüm",
    view_0: "Görünüm",
    warehouse: "Depo",
    warehouse_movement: "Depo hareketleri",
    warehouses: "Depolar",
    warehouses_0: "Depolar",
    warning: "Uyarı",
    web_site: "Web sitesi",
    website_name: "Web sitesinin adını belirtin",
    week: "Hafta",
    weekly: "Haftalık",
    weight: "Ağırlık",
    whence: "Nereden",
    where_to: "Nereye",
    without_amount: "Hesaplama olmadan",
    without_calculation: "Sözleşme No.",
    woman: "Kadın",
    women: "Kadınlar",
    work_schedule: "Çalışma programı",
    write_conclusions: "Sonuç yaz",
    write_of_from: "Silme tarihi itibariyle",
    write_off: "Silme",
    write_off_date: "Silme tarihi",
    write_off_items: "Silinecek öğeler",
    write_off_products: "Ürünleri sil",
    write_off_quantity: "Silme miktarı",
    write_offs: "Silinmeler",
    written_conclusions: "Yazılan sonuçlar",
    year: "Yıl",
    yearly: "Yıllık",
    yes: "Evet",
    yes_doctor: "Doktorla iletişim kur",
};
