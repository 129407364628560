<template>
  <div class="row table-sm mr-0 ml-0 p-0">
    <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">
      <div
        class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2"
      >
        <h5 class="d-inline mr-2 font-weight-bold">
          {{ $t("message.orders") }}
        </h5>
        <crm-refresh @c-click="refresh()"></crm-refresh>
        <div class="text-center d-flex sorddata ml-3">
          <el-input
            size="mini"
            :placeholder="$t('message.search')"
            prefix-icon="el-icon-search"
            v-model="filterForm.search"
            clearable
          ></el-input>
        </div>
      </div>
      <div
        class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset"
      >
        <div class="btn excel_btn" v-loading.body="loadingData">
          <button
              @click="statisticFilter(item, index)"
              class="filter-wrap__btn"
              :class="{ active: index == activeFilterIndex }"
              v-for="(item, index) in items"
              :key="index"
          >
            {{ $t(`message.${item}`) }}
          </button>
        </div>
        <export-excel
          v-can="'patients.excel'"
          class="btn excel_btn"
          :data="list"
          :fields="excel_fields"
          @fetch="controlExcelData()"
          worksheet="Заказы"
          name="Заказы.xls"
        >
          <el-button size="mini" icon="el-icon-document-delete"
            >Excel</el-button
          >
        </export-excel>
        <crm-column-settings
          :columns="columns"
          :modelName="'doctorCabinet'"
          @c-change="updateColumn"
        ></crm-column-settings>
      </div>
    </div>
    <table class="table table-bordered table-hover" v-loading="loadingData">
      <crm-pagination
        :pagination="pagination"
        @c-change="updatePagination"
      ></crm-pagination>
      <thead>
        <tr>
          <crm-th
            :column="columns.id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.patient_id"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.day"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :column="columns.service"
            :sort="sort"
            @c-change="updateSort"
          ></crm-th>
          <crm-th
            :sort="sort"
            :column="columns.settings"
            @c-change="updateSort"
          ></crm-th>
        </tr>
        <tr>
          <th v-if="columns.id.show">
            <el-input
              clearable
              size="mini"
              class="id_input"
              v-model="filterForm.id"
              :placeholder="columns.id.title"
            ></el-input>
          </th>
          <th v-if="columns.patient_id.show">
            <el-input
              size="mini"
              v-model="filterForm.patient_id"
              :placeholder="columns.patient_id.title"
              clearable
            ></el-input>
          </th>
          <th v-if="columns.day.show">
            <el-date-picker
              v-model="filterForm.day"
              :placeholder="columns.day.title"
              size="mini"
              :format="'dd.MM.yyyy'"
              :value-format="'dd.MM.yyyy'"
            ></el-date-picker>
          </th>
          <th v-if="columns.service.show">
            <!-- <select-service-name-inventory
              v-model="filterForm.service_id"
              :placeholder="columns.service.title"
              size="mini"
            >
            </select-service-name-inventory> -->
            <el-input
              size="mini"
              v-model="filterForm.service_name"
              :placeholder="columns.service.title"
              clearable
            >
            </el-input>
          </th>
          <th v-if="columns.settings.show"></th>
        </tr>
      </thead>

      <transition-group name="flip-list" tag="tbody">
        <tr
          v-for="patient in list"
          :key="patient.id"
          v-on:dblclick="show(patient.id)"
          style="cursor: pointer"
        >
          <template v-if="activeItem === 'ambulatory'">
            <td v-if="columns.id.show">
              {{ ambulatoryId(patient) }}
            </td>
            <td v-if="columns.patient_id.show">
              {{ patient.order && patient.order.patient ? patient.order.patient.surname + " " + patient.order.patient.first_name : "" }}
            </td>
            <td v-if="columns.day.show">{{ patient.order ? patient.order.day : '' }}</td>
            <td v-if="columns.service.show">
              {{ patient.service ? patient.service.name : "" }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <div class="za_btn text-center">
                <el-button
                  round
                  @click="show(patient)"
                  size="mini"
                  type="primary"
                  icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
                >
                <el-button
                  round
                  @click="showConclusion(patient)"
                  size="mini"
                  type="success"
                  icon="el-icon-view"
                >{{ $t("message.history") }}</el-button
                >
                <el-button
                  @click="openDialog(patient, 'order')"
                  type="primary"
                  icon="el-icon-edit"
                >{{ $t("message.receive_") }}</el-button
                >
              </div>
            </td>
          </template>
          <template v-if="activeItem === 'stationary'">
            <td v-if="columns.id.show">
              {{ patient.patient_history_id + "-" + patient.service_id + "-" + patient.patient_history.branch_id }}
            </td>
            <td v-if="columns.patient_id.show">
              {{ patient.patient_history.patient ? patient.patient_history.patient.surname + " " + patient.patient_history.patient.first_name : "" }}
            </td>
            <td v-if="columns.day.show">{{ patient.patient_history.date_gos }}</td>
            <td v-if="columns.service.show">
              {{ patient.service ? patient.service.name : "" }}
            </td>
            <td v-if="columns.settings.show" class="settings-td">
              <div class="za_btn text-center">
                <el-button
                    round
                    @click="show(patient)"
                    size="mini"
                    type="primary"
                    icon="el-icon-view"
                >{{ $t("message.show_0") }}</el-button
                >
                <el-button
                    round
                    @click="showConclusion(patient)"
                    size="mini"
                    type="success"
                    icon="el-icon-view"
                >{{ $t("message.history") }}</el-button
                >
                <el-button
                    @click="openDialog(patient, 'hospital')"
                    type="primary"
                    icon="el-icon-edit"
                >{{ $t("message.receive_") }}</el-button
                >
              </div>
            </td>
          </template>
        </tr>
      </transition-group>
    </table>

    <el-dialog
        :title="$t('message.write_off_items')"
        :append-to-body="true"
        :visible.sync="writeOffDialog"
        width="50%"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <el-table :data="writeOffProducts" style="width: 100%">
            <el-table-column align="center" width="50px">
              <template slot-scope="scope">
                <input
                    type="checkbox"
                    :value="scope.row"
                    @change="addProduct(scope.row, $event)"
                />
              </template>
            </el-table-column>
            <el-table-column label="№" prop="product.id" align="center" width="50px"/>
            <el-table-column :label="$t('message.name')" prop="product.name" align="center" width="450px"/>
            <el-table-column :label="$t('message.needed')" prop="quantity" align="center" width="130px"/>
            <el-table-column :label="$t('message.quantity')" align="center">
              <template slot-scope="scope">
                <el-input
                    clearable
                    v-model="scope.row.write_off_quantity"
                    size="mini"
                    :placeholder="$t('message.input')"
                    type="number"
                />
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.actions')">
              <template slot-scope="scope">
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="removeWriteOffItem(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="11"  class="mt-3">
          <products @append="append" v-model="form.product_id" :product_id="form.product_id"/>
        </el-col>
        <el-col :span="24" class="mt-2 mb-1">
          <el-button v-if="checkedOrders.length > 0" @click="writeOff(checkedOrders)" type="success" class="print-button">{{
              $t("message.write_off")
            }}</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
        :title="$t('message.do_you_want')"
        :append-to-body="true"
        :visible.sync="dialog_show"
        width="30%"
    >
      <el-checkbox
          :label="$t('message.create_conclusion')"
          v-model="create_flag"
      ></el-checkbox>
      <div class="dialog-footer">
        <el-button
            @click="closeDialog()"
            type="primary"
        >{{$t('message.cancel') }}</el-button>
        <el-button
            @click="createModal()"
            type="primary"
        >{{$t('message.receive_') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog
        :title="choose_template_header"
        :append-to-body="true"
        :visible.sync="outerOrderVisible"
        width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
              clearable
              :placeholder="$t('message.search_template')"
              v-model="filterText"
          ></el-input>
          <el-tree
              class="mt-2 filter-tree"
              :data="data"
              node-key="id"
              :filter-node-method="filterNode"
              ref="tree"
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion('order')" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()">
          {{ $t("message.close") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        :title="choose_template_header"
        :append-to-body="true"
        :visible.sync="signOuterVisible"
        width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24" v-for="(item, index) in signs" :key="index">
          {{ item.name }}
          <el-input clearable v-model="signs_form[item.sign]"></el-input>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusionSign()" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogCloseSign()">
          {{ $t("message.close") }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        :title="$t(`message.choose_template`)"
        :append-to-body="true"
        :visible.sync="outerHospitalVisible"
        width="40%"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-input
              clearable
              :placeholder="$t('message.search_template')"
              v-model="filterText"
          ></el-input>
          <el-tree
              class="mt-2 filter-tree"
              :data="data"
              node-key="id"
              :filter-node-method="filterNode"
              ref="tree"
              @node-click="handleNodeClick"
              :expand-on-click-node="false"
              show-checkbox
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="maxWidht ss">
                {{ data.name }}
              </span>
            </span>
          </el-tree>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button @click="createConclusion('hospital')" type="success">
          {{ $t("message.create") }}
        </el-button>
        <el-button @click="dialogClose()"> {{$t('message.close')}} </el-button>
      </div>
    </el-dialog>

    <el-drawer
      :visible.sync="drawerCreateCoculation"
      size="100%"
      :wrapperClosable="false"
      :drawer="drawerCreateCoculation"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
    >
      <div>
        <crm-create
          :reloadModel="reopenUpdate"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
          ref="createDrawer"
        ></crm-create>
      </div>
    </el-drawer>

    <el-drawer
      :visible.sync="drawerUpdateCoculation"
      size="85%"
      :wrapperClosable="false"
      :drawer="drawerUpdateCoculation"
      @open="reopenUpdate = true"
      @close="reloadIfChanged('reopenUpdate')"
      @closed="emptyModel"
      class="mytab_IdP"
    >
      <div>
        <crm-update
          :reloadModel="reopenUpdate"
          :patient="selected_patient"
          @open="reopenUpdate = true"
          @c-close="closeDrawer"
        ></crm-update>
      </div>
    </el-drawer>

    <el-drawer
      title="Изменить тег"
      size="70%"
      :wrapperClosable="false"
      :visible.sync="drawer.show.status"
      :ref="drawer.show.name"
      @opened="drawerOpened(drawer.show.component)"
      @closed="drawerClosed(drawer.show.component)"
    >
      <div>
        <crm-show
          :selected_id="selected_id"
          :reloadModel="reopenShow"
          :ref="drawer.show.component"
          :drawer-name="drawer.show.name"
        ></crm-show>
      </div>
    </el-drawer>
    <el-drawer
      title="Изменить тег"
      size="70%"
      :wrapperClosable="false"
      :visible.sync="drawer.conclusionShow.status"
      :ref="drawer.conclusionShow.name"
      @opened="drawerOpened(drawer.conclusionShow.component)"
      @closed="drawerClosed(drawer.conclusionShow.component)"
    >
      <div>
        <crm-all-show
          :selected_patient="selected_patient"
          :reloadModel="reopenShow"
          :ref="drawer.conclusionShow.component"
          :drawer-name="drawer.conclusionShow.name"
        ></crm-all-show>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./components/createConclusion";
import CrmShow from "./components/Parts/crm-lab-conculations-show";
import CrmAllShow from "./components/Parts/crm-all-conculations-show";
import { i18n } from "@/utils/i18n";
import CrmUpdate from "./components/addOrder";
import list from "@/utils/mixins/list";
import drawer from "@/utils/mixins/drawer";
import Inventory from "../../components/inventory-select/select-service_name-inventory";
import axios from "axios";
import products from "@/components/selects/products.vue";
export default {
  mixins: [list, drawer],
  name: "PatientServiceList",
  components: {
    products,
    CrmCreate,
    CrmUpdate,
    CrmShow,
    CrmAllShow,
    "select-service-name-inventory": Inventory,
  },
  data() {
    return {
      checkedOrders: [],
      backUrl: process.env.VUE_APP_URL_DOCS,
      backApiUrl: process.env.VUE_APP_URL,
      drawerCreateCoculation: false,
      drawerUpdateCoculation: false,
      outerOrderVisible: false,
      outerHospitalVisible: false,
      choose_template_header: '',
      filterForm: {},
      filterText: "",
      reopenUpdate: false,
      excel_data: [],
      reopenShow: false,
      drawerShow: false,
      showRef: "drawerRef",
      excel_fields: {},
      selected_id: null,
      loadingData: false,
      selected_patient: [],
      patient: null,
      create_flag: false,
      dialog_show: false,
      signOuterVisible: false,
      writeOffDialog: false,
      writeOffProducts: [],
      form: {},
      username: '',
      data: null,
      signs: [],
      signs_form: {},
      drawer: {
        show: {
          name: "show",
          status: false,
          component: "componentDrawerShow",
        },
        conclusionShow: {
          name: "conclusionShow",
          status: false,
          component: "componentDrawerConclusionShow",
        },
      },
      activeItem: 'ambulatory',
      activeFilterIndex: 1,
      items: {
        1: "ambulatory",
        2: "stationary"
      }
    };
  },
  watch: {
    activeItem: {
      handler: function () {
        this.fetchData();
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    ...mapGetters({
      list: "doctorCabinet/informal_patient_list",
      columns: "doctorCabinet/columns",
      pagination: "doctorCabinet/pagination",
      filter: "doctorCabinet/filter",
      sort: "doctorCabinet/sort",
      conditions: "conditions/list",
      token: "auth/token",
      user_id: "auth/id",
    }),
  },
  async created() {},
  async mounted() {
    await this.controlExcelData();
    if (this.conditions && this.conditions.length === 0)
      await this.loadConditions({ doctor: true });
    this.categoryDocumentationList({ relation: true })
      .then((res) => {
        this.data = res.data.category_forms;
      })
      .catch((err) => {
        console.log(err);
      });
    this.defaulFilterItem = this.items[this.activeFilterIndex];
    this.statisticFilter(this.defaulFilterItem, this.activeFilterIndex);
  },
  methods: {
    ...mapActions({
      updateList: "doctorCabinet/informalPatientList",
      updateOrderDoctor: "doctorCabinet/updateOrderDoctor",
      updateHospitalDoctor: "doctorCabinet/updateHospitalDoctor",
      categoryDocumentationList: "categoryDocumentation/index",
      showModel: "laboratoryConclusions/showOrder",
      updateSort: "doctorCabinet/updateSort",
      updateFilter: "doctorCabinet/updateFilter",
      updateColumn: "doctorCabinet/updateColumn",
      updatePagination: "doctorCabinet/updatePagination",
      empty: "doctorCabinet/empty",
      refreshData: "doctorCabinet/refreshData",
      loadConditions: "conditions/index",
      setOrderSigns: "doctorCabinet/setSigns",
      setHospitalSigns: "hospitalizationServices/setSigns",
      getServiceItems: "services/getItems",
      autoWriteOff: "autoWriteOffs/store",
      currentUser: "auth/getAuth",
      close: "orders/orderClose",
    }),
    ambulatoryId(model) {
      let id = model.order_id + "-" + model.service_id;
      if (model.position > 1) id += "(" + model.position + ")";
      return id;
    },
    stationaryId(model) {
      let id = model.patient_history_id + "-" + model.service_id + "-" + model.patient_history.branch_id;
      if (model.position > 1) id += "(" + model.position + ")";
      return id;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    dialogCloseSign() {
      this.signOuterVisible = false;
      this.create_flag = false;
      this.signs = [];
    },
    dialogClose() {
      this.$refs.tree.setCheckedNodes([]);
      this.outerOrderVisible = false;
      this.create_flag = false;
      this.form = {};
      this.model = null;
    },
    async fetchData() {
      const query = {
        type: this.activeItem,
        ...this.filterForm,
        ...this.pagination,
        ...this.sort,
      };
      if (!this.loadingData) {
        this.loadingData = true;
        await this.updateList(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData();
    },
    async show(model) {
      this.drawer.show.status = true;
      this.selected_id = model.order_id;
    },
    showConclusion(model) {
      // this.$loadingCursor("wait");
      this.drawer.conclusionShow.status = true;
      this.selected_patient = model.patient;
    },
    createModal() {
      this.$loadingCursor("wait");
      if (this.activeItem === 'ambulatory') {
        this.updateOrderDoctor(this.patient.id)
          .then((res) => {
            if (this.create_flag === true) {
              this.dialogOpen();
            }
            this.refresh();
          })
          .finally((fin) => {
            this.$loadingCursor("default");
            this.closeDialog();
          });
      } else if (this.activeItem === 'stationary') {
        this.updateHospitalDoctor(this.patient.id)
          .then((res) => {
            if (this.create_flag === true) {
              this.dialogOpen();
            }
            this.refresh();
          })
          .finally((fin) => {
            this.$loadingCursor("default");
            this.closeDialog();
          });
      }
    },
    controlExcelData() {
      this.excel_fields = {};
      for (let key in this.columns) {
        if (this.columns.hasOwnProperty(key)) {
          let column = this.columns[key];
          if (column.show && column.column !== "settings") {
            this.excel_fields[column.title] = column.column;
          }
        }
      }
    },
    emptyModel() {
      this.$refs["createDrawer"].afterClosed();
      this.empty();
    },
    statisticFilter(item, index) {
      this.activeItem = item;
      this.activeFilterIndex = index;
    },
    openDialog(patient) {
      this.patient = patient;
      if (patient.service.unnecessary_enclosure === false) {
        this.dialog_show = true;
      } else {
        this.createModal();
      }
    },
    closeDialog() {
      // this.patient = null;
      this.create_flag = false;
      this.dialog_show = false;
    },
    dialogOpen() {
      const model = this.patient;
      let name;
      if (this.activeItem === 'ambulatory') {
        name =
            this.ambulatoryId(model) +
            " " +
            model.order.patient.surname +
            " " +
            model.order.patient.first_name;
      } else if (this.activeItem === 'stationary') {
        name =
            model.patient_history_id +
            "-" +
            model.service_id +
            "-" +
            model.patient_history.branch_id +
            " " +
            model.patient_history.patient.surname +
            " " +
            model.patient_history.patient.first_name;
      }
      const service_name = model.service ? model.service.name : (model.service ? model.service.name : '');
      this.choose_template_header = name + ' \n\r (' + service_name + ')';
      axios({
        url: this.backUrl + "/check/" + name,
        method: "GET",
      })
        .then((res) => {
          if (res.data == true) {
            if (this.activeItem === 'ambulatory') {
              this.createOrderModal(null);
            }
            else if (this.activeItem === 'stationary') {
              this.createHospitalModal(null);
            }
          } else {
            if (model.service.auto_write_off === true) {
              axios({
                url: this.backApiUrl + "/autoWriteOff/check/" + this.activeItem + "/" + model.id,
                method: "GET",
              }).then((res) => {
                console.log('res.data', res.data)
                if (res.data == false) {
                  console.log(model.service_id)
                  this.getServiceItems(model.service_id)
                    .then(res => {
                      const products = res.data.items;
                      products.forEach(product => {
                        this.writeOffProducts = [];
                        this.writeOffProducts.push({
                          quantity: product.quantity,
                          write_off_quantity: product.quantity,
                          product: {
                            id: product.product.id,
                            name: product.product.name,
                          },
                        })
                      });
                      if (model.service.write_off_notify == true) {
                        this.writeOffDialog = true;
                      } else {
                        this.writeOff(this.writeOffProducts);
                      }
                    })
                } else {
                  this.checkLaboratory();
                }
              })
            } else {
              this.checkLaboratory();
            }
          }
        })
        .catch((err) => {
          this.outerOrderVisible = true;
        });
    },
    checkLaboratory() {
      const model = this.patient;
      axios({
        url:
          this.backUrl +
          "/checkLaboratory/" +
          this.activeItem +
          "/" +
          (model.order_id ? model.order_id : model.patient_history_id) +
          "/" +
          model.service_id,
        method: "GET",
      })
        .then((res) => {
          if (res.data == false) this.outerOrderVisible = true;
          else {
            this.signs = res.data;
            this.signOuterVisible = true;
          }
        })
        .catch((err) => {
          this.outerOrderVisible = true;
        });
    },
    createConclusion() {
      let item = this.$refs.tree.getCheckedNodes();

      let template_id = null;
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0 && element.category_id) {
            template_id = element.id;
          }
        }
      }
      if (this.activeItem === 'ambulatory') {
        this.createOrderModal(template_id);
      } else if (this.activeItem === 'stationary') {
        this.createHospitalModal(template_id);
      }
    },
    createConclusionSign() {
      let model = this.patient;
      let data = {};
      if (this.activeItem === 'ambulatory') {
        data["fileName"] =
            this.ambulatoryId(model) +
            " " +
            model.order.patient.surname +
            " " +
            model.order.patient.first_name;

        data["order_id"] = model.order_id;
        data["user"] = this.token;
        data["service_id"] = model.service_id;
        data["position"] = model.position;
        data["patient_id"] = model.order.patient_id;
        data["user_id"] = this.user_id;
        data["signs"] = this.signs_form;

        this.setOrderSigns(data)
            .then((res) => {
              this.signOuterVisible = false;
              this.signs_form = {};
              this.fetchData();
            })
            .catch((err) => {});
      } else if (this.activeItem === 'stationary') {
        data["fileName"] =
            model.patient_history_id +
            "-" +
            model.service_id +
            " " +
            model.patient_history.patient.surname +
            " " +
            model.patient_history.patient.first_name;

        data["patient_history_id"] = model.patient_history_id;
        data["user"] = this.token;
        data["service_id"] = model.service_id;
        data["patient_id"] = model.patient_history.patient_id;
        data["user_id"] = this.user_id;
        data["signs"] = this.signs_form;

        this.setHospitalSigns(data)
            .then((res) => {
              this.signOuterVisible = false;
              this.signs_form = {};
              this.fetchData();
            })
            .catch((err) => {});
      }
    },
    handleNodeClick(node, data, value) {},
    async createOrderModal(template_id) {
      const model = this.patient;
      const name =
          this.ambulatoryId(model) +
          " " +
          model.order.patient.surname +
          " " +
          model.order.patient.first_name;
      const link = document.createElement("a");
      const service_id = model.service_id;
      link.setAttribute(
          "href",
          this.backUrl +
          "/file_orientation?order_id=" +
          model.order_id +
          "&name=" +
          name +
          "&template_id=" +
          template_id +
          "&service_id=" +
          service_id +
          "&position=" +
          model.position +
          "&user=" +
          this.token +
          "&status=create" +
          "&user_type=doctor"
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();

      return;
    },
    async createHospitalModal(template_id) {
      var link = document.createElement("a");
      link.setAttribute(
          "href",
          this.backUrl +
          "/file_orientation_patient_room?template_id=" +
          template_id +
          "&user=" +
          this.token +
          "&status=create" +
          "&service_id=" +
          this.patient.service_id +
          "&patient_id=" +
          this.patient.patient_history.patient_id
      );
      link.setAttribute("target", "_blank");
      link.click();
      this.dialogClose();
      return;
    },
    addProduct(item, event) {
      if (event.target.checked == true) {
        this.checkedOrders.push(item);
      }
      if (event.target.checked == false) {
        const index = this.checkedOrders.indexOf(item);
        if (index > -1) {
          this.checkedOrders.splice(index, 1);
        }
      }
    },
    removeWriteOffItem(item) {
      const index = this.writeOffProducts.indexOf(item);
      this.writeOffProducts.splice(index, 1);
    },
    append(product) {
      this.loadingItems = true;
      if (!this.writeOffProducts.find((item) => item.product.id == product.id)) {
        this.writeOffProducts.push({
          quantity: '',
          write_off_quantity: '',
          product: {
            id: product.id,
            name: product.name,
          },
        });
      }
    },
    async writeOff(products) {
      const user = await this.currentUser();

      const query = {
        user_id: user.id,
        products: products,
        writable_type: this.activeItem,
        writable_id: this.patient.id,
      };
      this.autoWriteOff(query)
          .then((res) => {
            this.writeOffDialog = false;
            this.$notify({
              title: 'Успешно',
              type: "success",
              offset: 130,
              message: res.message,
            });
            if (this.patient.service.unnecessary_enclosure == true) {
              this.close(this.model.id);
              this.refreshData();
            } else {
              this.checkLaboratory();
            }
          })
          .catch((err) => {
            let message;
            if (err.errors['products.0.write_off_quantity']) {
              message = err.errors['products.0.write_off_quantity'][0];
            } else {
              message = err.message;
            }

            this.$notify({
              title: 'Ошибка',
              type: "error",
              offset: 130,
              message: message
            });
          })
    }
  },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 16px;

  @media screen and (max-width: 1168px) {
    gap: 1rem;
    flex-wrap: wrap;
  }

  // filter-wrap__btn
  &__btn {
    outline: none;
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 6px 20px;
    color: #606266;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.15s ease-in-out;

    &.active {
      color: #fff;
      background: #409eff;
    }

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      color: #fff;
      background: #66b1ff;
    }

    &:active {
      background: #3a8ee6;
    }
  }
}
.router_style {
  td {
    padding: 0 !important;
    margin: 0px !important;
    a {
      display: block;
      padding: 0.3rem;
      color: #303030;
      font-weight: 200 !important;
      font-size: 14px !important;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>



