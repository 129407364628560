import { sort } from './../properties/sort';
import { pagination } from './../properties/pagination';
import { filter } from './properties/filter';
import { model } from './properties/model';
import { i18n } from '@/utils/i18n';

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_FILTER: (state, filter) => {
    state.filter = filter;
  },
  SET_SORT: (state, sort) => {
    state.sort = sort;
  },
  SET_MODEL: (state, model) => {
    state.model = model;
  },
  SET_INVENTORY: (state, inventory) => {
    state.inventory = inventory;
  },
  UPDATE_COLUMN: (state, column) => {
    state.columns[column.key].show = column.value;
  },
  SET_COLUMN_STOCK: (state, column) => {
    state.columnsForStuckBunk[column.key].show = column.value;
  },
  UPDATE_SORT: (state, sort) => {
    state.sort = sort;
  },
  UPDATE_PAGINATION: (state, pagination) => {
    state.pagination[pagination.key] = pagination.value;
  },
  EMPTY_MODEL: (state) => {
    state.model = JSON.parse(JSON.stringify(model))
  },
  REFRESH: (state) => {
    state.filter = JSON.parse(JSON.stringify(filter));
    // state.sort = JSON.parse(JSON.stringify(sort));
    state.pagination = JSON.parse(JSON.stringify(pagination));
  },
  UPDATE_LANG_COLUMN: (state) => {
    state.columns.id.title = '№';
    state.columns.name.title = i18n.t('message.name');
    state.columns.warehouse_id.title = i18n.t('message.warehouse');
    state.columns.user.title = i18n.t('message.recipient');
    state.columns.code.title = i18n.t('message.code');
    state.columns.sku.title = i18n.t('message.sku');
    state.columns.category.title = i18n.t('message.group');
    state.columns.measurement.title = i18n.t('message.measurement');
    state.columns.vat.title = i18n.t('message.nds');
    state.columns.available.title = i18n.t('message.remaind');
    state.columns.used.title = i18n.t('message.used');
    state.columns.received.title = i18n.t('message.qty_come');
    state.columns.created_at.title = i18n.t('message.date');
    state.columns.updated_at.title = i18n.t('message.update_date');
    state.columns.settings.title = i18n.t('message.settings');

    state.columnsForStuckBunk.id.title = '№';
    state.columnsForStuckBunk.name.title = i18n.t('message.name');
    state.columnsForStuckBunk.warehouse_id.title = i18n.t('message.warehouse');
    state.columnsForStuckBunk.user.title = i18n.t('message.recipient');
    state.columnsForStuckBunk.price.title = i18n.t('message.price');
    state.columnsForStuckBunk.serial_number.title = i18n.t('message.serial_number');
    state.columnsForStuckBunk.code.title = i18n.t('message.code');
    state.columnsForStuckBunk.category.title = i18n.t('message.group');
    state.columnsForStuckBunk.measurement.title = i18n.t('message.measurement');
    state.columnsForStuckBunk.received.title = i18n.t('message.qty_come');
    state.columnsForStuckBunk.created_at.title = i18n.t('message.date');
    state.columnsForStuckBunk.settings.title = i18n.t('message.settings');

  }
}