import {mapMutations} from "vuex";

export default {
    data() {
        return {
            env_language: parseInt(process.env.VUE_APP_LANGUAGE),
        }
    },
    methods: {
    ...mapMutations({
        updateLangBunk: "bunks/UPDATE_LANG_COLUMN",
        updateLangbranches: "branches/UPDATE_LANG_COLUMN",
        updateLangcategories: "categories/UPDATE_LANG_COLUMN",
        updateLangcategoryDocumentation: "categoryDocumentation/UPDATE_LANG_COLUMN",
        updateLangcategoryServices: "categoryServices/UPDATE_LANG_COLUMN",
        updateLangcities: "cities/UPDATE_LANG_COLUMN",
        updateLangcitizenships: "citizenships/UPDATE_LANG_COLUMN",
        updateLangclassses: "classses/UPDATE_LANG_COLUMN",
        updateLangconclusions: "conclusions/UPDATE_LANG_COLUMN",
        updateLangconditions: "conditions/UPDATE_LANG_COLUMN",
        updateLangcontracts: "contracts/UPDATE_LANG_COLUMN",
        updateLangcorpses: "corpses/UPDATE_LANG_COLUMN",
        updateLangcostTransactions: "costTransactions/UPDATE_LANG_COLUMN",
        updateLangcosts: "costs/UPDATE_LANG_COLUMN",
        updateLangcurrencies: "currencies/UPDATE_LANG_COLUMN",
        updateLangdailyTreatmentIndeces: "dailyTreatmentIndeces/UPDATE_LANG_COLUMN",
        updateLangdailyTreatments: "dailyTreatments/UPDATE_LANG_COLUMN",
        updateLangdepartmentUsers: "departmentUsers/UPDATE_LANG_COLUMN",
        updateLangdoctorCabinet: "doctorCabinet/UPDATE_LANG_COLUMN",
        updateLangdoctorsReport: "doctorsReport/UPDATE_LANG_COLUMN",
        updateLangdocumentTypes: "documentTypes/UPDATE_LANG_COLUMN",
        updateLangfloors: "floors/UPDATE_LANG_COLUMN",
        updateLangformTemplate: "formTemplate/UPDATE_LANG_COLUMN",
        updateLanghospitalPatients: "hospitalPatients/UPDATE_LANG_COLUMN",
        updateLanghospitalRooms: "hospitalRooms/UPDATE_LANG_COLUMN",
        updateLanghospitalizationConclusions: "hospitalizationConclusions/UPDATE_LANG_COLUMN",
        updateLanghospitalizationServices: "hospitalizationServices/UPDATE_LANG_COLUMN",
        updateLanghours: "hours/UPDATE_LANG_COLUMN",
        updateLanginjuryStatuses: "injuryStatuses/UPDATE_LANG_COLUMN",
        updateLanginjuryTypes: "injuryTypes/UPDATE_LANG_COLUMN",
        updateLanginvoice: "invoice/UPDATE_LANG_COLUMN",
        updateLangmeasurements: "measurements/UPDATE_LANG_COLUMN",
        updateLangmovings: "movings/UPDATE_LANG_COLUMN",
        updateLangnationalities: "nationalities/UPDATE_LANG_COLUMN",
        updateLangnurceOfficePhies: "nurceOfficePhies/UPDATE_LANG_COLUMN",
        updateLangnurceOffices: "nurceOffices/UPDATE_LANG_COLUMN",
        updateLangorders: "orders/UPDATE_LANG_COLUMN",
        updateLangorganizations: "organizations/UPDATE_LANG_COLUMN",
        updateLangpartnerDoctor: "partnerDoctor/UPDATE_LANG_COLUMN",
        updateLangpartnerDoctorReport: "partnerDoctorReport/UPDATE_LANG_COLUMN",
        updateLangpartnerClinic: "partnerClinic/UPDATE_LANG_COLUMN",
        updateLangpartnerClinicReport: "partnerClinicReport/UPDATE_LANG_COLUMN",
        updateLangpatientBalances: "patientBalances/UPDATE_LANG_COLUMN",
        updateLangpatientFiles: "patientFiles/UPDATE_LANG_COLUMN",
        updateLangpatientHistories: "patientHistories/UPDATE_LANG_COLUMN",
        updateLangpatientImages: "patientImages/UPDATE_LANG_COLUMN",
        updateLangpatientIndicatorIndeces: "patientIndicatorIndeces/UPDATE_LANG_COLUMN",
        updateLangpatientIndicatorNames: "patientIndicatorNames/UPDATE_LANG_COLUMN",
        updateLangpatientIndicators: "patientIndicators/UPDATE_LANG_COLUMN",
        updateLangpatientMedicine: "patientMedicine/UPDATE_LANG_COLUMN",
        updateLangpatientTransactions: "patientTransactions/UPDATE_LANG_COLUMN",
        updateLangpatients: "patients/UPDATE_LANG_COLUMN",
        updateLangpayment: "payment/UPDATE_LANG_COLUMN",
        updateLangpaymentBonus: "paymentBonus/UPDATE_LANG_COLUMN",
        updateLangpaymentTypes: "paymentTypes/UPDATE_LANG_COLUMN",
        updateLangpermissions: "permissions/UPDATE_LANG_COLUMN",
        updateLangphysiotherapies: "physiotherapies/UPDATE_LANG_COLUMN",
        updateLangphysiotherapyIndeces: "physiotherapyIndeces/UPDATE_LANG_COLUMN",
        updateLangprintAuditable: "printAuditable/UPDATE_LANG_COLUMN",
        updateLangprintlogos: "printlogos/UPDATE_LANG_COLUMN",
        updateLangprocurements: "procurements/UPDATE_LANG_COLUMN",
        updateLangproducts: "products/UPDATE_LANG_COLUMN",
        updateLangpurposeHospitalizations: "purposeHospitalizations/UPDATE_LANG_COLUMN",
        updateLangregions: "regions/UPDATE_LANG_COLUMN",
        updateLangremainders: "remainders/UPDATE_LANG_COLUMN",
        updateLangroles: "roles/UPDATE_LANG_COLUMN",
        updateLangrooms: "rooms/UPDATE_LANG_COLUMN",
        updateLangschedulesCabinets: "schedulesCabinets/UPDATE_LANG_COLUMN",
        updateLangservices: "services/UPDATE_LANG_COLUMN",
        updateLangsocials: "socials/UPDATE_LANG_COLUMN",
        updateLangspecialties: "specialties/UPDATE_LANG_COLUMN",
        updateLangstates: "states/UPDATE_LANG_COLUMN",
        updateLangstatuses: "statuses/UPDATE_LANG_COLUMN",
        updateLangsupplierContracts: "supplierContracts/UPDATE_LANG_COLUMN",
        updateLangsuppliers: "suppliers/UPDATE_LANG_COLUMN",
        updateLangtags: "tags/UPDATE_LANG_COLUMN",
        updateLangtransaction: "transaction/UPDATE_LANG_COLUMN",
        updateLangtreatmentProductList: "treatmentProductList/UPDATE_LANG_COLUMN",
        updateLangtypeHospitalizations: "typeHospitalizations/UPDATE_LANG_COLUMN",
        updateLangusers: "users/UPDATE_LANG_COLUMN",
        updateLangwarehouseProducts: "warehouseProducts/UPDATE_LANG_COLUMN",
        updateLangwarehouses: "warehouses/UPDATE_LANG_COLUMN",
        updateLangworkTimes: "workTimes/UPDATE_LANG_COLUMN",
        updateLangwriteOffs: "writeOffs/UPDATE_LANG_COLUMN",
        updateExpiredLangorders: "orders/UPDATE_EXPIRED_LANG_COLUMN",
        updateLangorders2: "orders/UPDATE_LANG_COLUMN2",
        updateLangnurseCabinet: "nurseCabinet/UPDATE_LANG_COLUMN",
        updateLangdebtors: "debtors/UPDATE_LANG_COLUMN",
        updateLangdebts: "debts/UPDATE_LANG_COLUMN",
        updateLanginsuranceCompany: "insuranceCompany/UPDATE_LANG_COLUMN",
        updateLanginsuranceCompanyReport: "insuranceCompanyReport/UPDATE_LANG_COLUMN",
        updateLangpocketsName: "pocketsName/UPDATE_LANG_COLUMN",
        updateLangrequirements: "requirements/UPDATE_LANG_COLUMN",
        updateLangpaymentReport: "paymentReport/UPDATE_LANG_COLUMN",
        updateLangpatientResidenceReport: "patientResidenceReport/UPDATE_LANG_COLUMN",
        updateLangpatientServiceReport: "patientServiceReport/UPDATE_LANG_COLUMN",
        updateLangownerClinic: "ownerClinic/UPDATE_LANG_COLUMN",
        updateLangorderColumn: "orderColumn/UPDATE_LANG_COLUMN",
        updateLangexcelColumns: "excelColumns/UPDATE_LANG_COLUMN",
        updateLangownerBranches: "ownerBranches/UPDATE_LANG_COLUMN",
        updateLangdoctorSigns: "doctorSigns/UPDATE_LANG_COLUMN",
        updateLangcouriers: "couriers/UPDATE_LANG_COLUMN",
      }),
      handleCommandLangChange(command, reload = true) {
        this.loading = true;

        if (reload) window.location.reload();

        this.$root.$i18n.locale = command;
        localStorage.setItem("currentLang", command);

        this.langSend({ lang: command === "uz" ? "uz-latin" : command });

        for (const mutation of Object.keys(this.$options.methods)) {
          if (mutation.startsWith("updateLang") && typeof this[mutation] === "function") {
            this[mutation]();
          }
        }
      },
    }
}