<template>
  <div class="login-layout">
    <el-row>
      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
        <div class="loginfon"><img src="img/fon4.png" alt="" /></div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" class="login">
        <div class="login_logo"><img src="/img/logoDark.png" alt="" /></div>
        <div class="form-box">
          <el-row :gutter="20">
            <el-col :span="16">
              <h2 class="form-box__title">{{ $t("message.login") }}</h2>
            </el-col>
            <el-col :span="8">
              <div v-if="env_language == 1">
                <el-dropdown trigger="click" class="flag" @command="handleCommandLangChange" style="border-color: transparent">
                  <span class="el-dropdown-link">
                    <img v-if="$root.$i18n.locale == 'tr'" src="/img/flag/turkey.svg" alt class="flag_img" />
                    <img v-if="$root.$i18n.locale == 'uz'" src="/img/flag/uzbekistan.svg" alt class="flag_img" />
                    <img v-if="$root.$i18n.locale == 'ru'" src="/img/flag/russia.svg" alt class="flag_img" />
                    <img v-if="$root.$i18n.locale == 'en'" src="/img/flag/UK.svg" alt class="flag_img" />
                    <i class="el-icon-arrow-down el-icon--right"/>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="flag_ul">
                    <el-dropdown-item command="tr" v-if="$root.$i18n.locale != 'tr'">
                      <img src="/img/flag/turkey.svg" alt class="flag_img flag_img2" />
                      <span>Turkey</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="uz" v-if="$root.$i18n.locale != 'uz'">
                      <img src="/img/flag/uzbekistan.svg" alt class="flag_img flag_img2" />
                      <span>Uzbekistan</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="ru" v-if="$root.$i18n.locale != 'ru'">
                      <img src="/img/flag/russia.svg" alt class="flag_img flag_img2" />
                      <span>Russia</span>
                    </el-dropdown-item>
                    <el-dropdown-item command="en" v-if="$root.$i18n.locale != 'en'">
                      <img src="/img/flag/UK.svg" alt class="flag_img flag_img2" />
                      <span>English</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-col>
          </el-row>
          <el-form
            class="login-form radus"
            :model="form"
            :rules="rules"
            ref="form"
            @submit.native.prevent="login"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="Phone"
                prefix-icon="el-icon-user"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="form.password"
                placeholder="Password"
                type="password"
                prefix-icon="el-icon-lock"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="loading"
                :disabled="loading ? true : false"
                class="login-button"
                type="primary"
                native-type="submit"
                block
                >{{ $t("message.sign_in") }}
              </el-button>
            </el-form-item>
            <!-- <el-button type="text" @click="passwordDialogVisible = true">Забыли пароль ?</el-button> -->
          </el-form>
        </div>
      </el-col>
    </el-row>

    <!-- MODAL DIALOG -->
    <el-dialog
      title="Creating Password"
      :visible.sync="passwordDialogVisible"
      width="23%"
    >
      <div v-loading="loading_validate">
        <!-- Send phone number -->
        <div v-if="valid.phone">
          <el-form class="login-form radus">
            <el-form-item>
              <el-input
                v-model="model.valid_phone"
                placeholder="Phone"
                prefix-icon="el-icon-user"
              />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="passwordDialogVisible = false">Cancel</el-button>
            <el-button type="primary" @click="send_phone">Confirm</el-button>
          </span>
        </div>
        <!-- Validate Code -->
        <div v-if="valid.phone == false && valid.confirm_code == false">
          <el-form class="login-form radus">
            <el-form-item>
              <el-input
                v-model="model.confirm_code"
                placeholder="Confirm code"
              />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirmCode">Send code</el-button>
          </span>
        </div>
        <!-- send password -->
        <div v-if="valid.confirm_code && valid.password == false">
          <el-form class="login-form radus">
            <el-form-item>
              <el-input
                v-model="model.password"
                placeholder="Password"
                type="password"
                prefix-icon="el-icon-lock"
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="model.confirm_password"
                placeholder="Confirm Password"
                type="password"
                prefix-icon="el-icon-lock"
              />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="resetPassword" type="primary">Confirm</el-button>
          </span>
        </div>
        <!-- // send password -->
      </div>
    </el-dialog>
    <el-dialog
        :title="$t('message.choose_branch')"
        :visible.sync="branchDialogVisible"
        width="20%"
    >
      <div class="za_btn block-layout">
        <el-select
            v-if="owner_branches.length > 1"
            v-model="owner_branch_id"
            :placeholder="$t('message.choose_owner_branch')"
            class="select-with-margin"
            clearable
        >
          <el-option
              v-for="owner_branch in owner_branches"
              :key="owner_branch.owner_branch_id"
              :label="owner_branch.owner_branch.name"
              :value="owner_branch.owner_branch_id"
          />
        </el-select>
        <template v-else-if="owner_branches.length === 1">
          <span>{{ owner_branches[0].name }}</span>
        </template>

        <el-select
            v-if="branches && branches.length > 1"
            v-model="branch_id"
            :placeholder="$t('message.choose_branch')"
            class="select-with-margin"
        >
          <el-option
              v-for="branch in branches"
              :key="branch.id"
              :label="branch.name"
              :value="branch.id"
          ></el-option>
        </el-select>
        <template v-if="branches.length === 1">
          <span>{{ branches[0].name }}</span>
        </template>

        <el-button
            v-if="branch_id"
            @click="setBranch()"
            type="success"
            icon="el-icon-edit"
            class="button-with-margin"
        >
          {{ $t('message.receive_') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import languageMixin from "@/utils/mixins/languageMixin";
export default {
  mixins: [languageMixin],
  name: "login",
  data() {
    return {
      user: [],
      phone: "",
      branch_id: null,
      branches: [],
      owner_branch_id: null,
      owner_branches: [],
      form: {
        password: "",
        phone: "",
      },
      validCredentials: {
        username: "lightscope",
        password: "lightscope",
      },
      valid: {
        phone: true,
        confirm_code: false,
        password: false,
      },
      model: {
        valid_phone: "",
        confirm_code: null,
        password: "",
        confirm_password: "",
      },
      loading: false,
      loading_validate: false,
      passwordDialogVisible: false,
      branchDialogVisible: false,
      rules: {
        phone: [
          {
            required: true,
            message: "Пожалуйста, введите телефон",
            trigger: "blur",
          },
          {
            min: 13,
            max: 13,
            message: "Длина должна быть 13",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Пожалуйста, введите телефон",
            trigger: "blur",
          },
          {
            min: 5,
            message: "Длина должна быть 5",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
    owner_branch_id: {
      handler: function () {
        if (this.owner_branch_id != '') {
          this.getDepartments();
        }
      }
    }
  },
  methods: {
    ...mapActions({
      postLogin: "auth/login",
      passwordReset: "users/resetPassword",
      code: "users/code",
      restore: "users/restore",
      currentUser: "auth/getAuth",
      setUserBranch: "users/setDoctorBranch",
      getOwnerBranchDepartments: "branches/getOwnerBranchDepartments",
    }),
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    login() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postLogin(this.form)
            .then(async (res) => {

              this.user = await this.currentUser();

              this.owner_branches = this.user.owner_branch;
              this.branchDialogVisible = true;

              if (this.owner_branches.length === 1) {
                this.owner_branch_id = this.owner_branches[0].owner_branch_id;
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
              this.Message_Error(
                err.response.data.code,
                err.response.data.message
              );
            });
        }
      });
    },
    getDepartments() {
      const query = {
        id: this.owner_branch_id,
      };
      this.getOwnerBranchDepartments(query)
        .then((res) => {
          this.branches = res.data.branches;

          if (this.branches.length === 1) {
            this.branch_id = this.branches[0].id;
            this.setBranch();
          }
        });
    },
    setBranch() {
      const query = {
        user_id: this.user.id,
        branch_id: this.branch_id
      };
      this.setUserBranch(query)
        .then((res) => {
          this.$router.push({
            path: this.redirect || "/",
            query: this.otherQuery,
          });
        });
    },
    send_phone() {
      if (this.model.valid_phone.length != 13) {
        this.Message_Error("", "Номер телефона введен неверно");
        return;
      }
      this.loading_validate = true;
      this.restore({ phone: this.model.valid_phone })
        .then((res) => {
          this.valid.phone = false;
          this.Message_Success("This code: " + res.data.validate_code);
          this.loading_validate = false;
        })
        .catch((err) => {
          this.loading_validate = false;
          this.Message_Error(err.code, err.error.message);
        });
    },
    confirmCode() {
      if (this.model.confirm_code == null) {
        this.Message_Error("", "Код не введен");
        return;
      }
      this.loading_validate = true;
      this.code({ code: this.model.confirm_code })
        .then((res) => {
          this.user = res.data.user;
          this.valid.confirm_code = false;
          this.valid.confirm_code = true;
          this.loading_validate = false;
        })
        .catch((err) => {
          this.loading_validate = false;
          this.Message_Error("", err.error.message);
        });
    },
    resetPassword() {
      if (
        this.model.password != this.model.confirm_password &&
        this.model.password != "" &&
        this.model.confirm_password != ""
      ) {
        this.Message_Error(
          "",
          "Код не совпадает, пожалуйста, проверьте cеще раз"
        );
        return false;
      }
      this.loading_validate = true;
      this.passwordReset({ id: this.user.id, password: this.model.password })
        .then((res) => {
          (this.valid.phone = true),
            (this.valid.confirm_code = false),
            (this.valid.password = false);
          this.loading_validate = false;
          this.passwordDialogVisible = false;
          this.Message_Success("Ваш пароль изменился, пожалуйста войдите");
        })
        .catch((err) => {
          console.log(err);
          this.loading_validate = false;
        });
    },
    Message_Success(message) {
      this.$notify.success({
        dangerouslyUseHTMLString: true,
        title: "Success",
        message: "<strong>" + message + "</strong>",
        duration: 6000,
      });
    },
    Message_Error(code, message) {
      this.$notify.error({
        dangerouslyUseHTMLString: true,
        title: "Error " + code,
        message: "<strong>" + message + "</strong>",
        position: "bottom-right",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.login_logo {
  width: 300px;
  display: block;
  margin-bottom: 30px;
}

.login_logo img {
  width: 100%;
}

.login-form {
  width: 290px;
}

.forgot-password {
  margin-top: 10px;
  color: #224b69;
}

.block-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-with-margin {
  width: 100%;
  margin-bottom: 15px;
}

.button-with-margin {
  margin-top: 15px;
  width: 100%;
}
</style>

<style lang="scss">
$teal: rgb(0, 124, 137);

// .el-button--primary {
//   background: $teal;
//   border-color: $teal;

//   &:hover,
//   &.active,
//   &:focus {
//     background: lighten($teal, 7);
//     border-color: lighten($teal, 7);
//   }
// }

.login .el-input__inner:hover {
  border-color: $teal;
}

.login .el-input input {
  padding-left: 35px;
}

.login .el-card {
  padding-top: 0;
  padding-bottom: 30px;
}

.auth_h2 {
  padding-bottom: 20px;
}

a {
  color: $teal;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: lighten($teal, 7);
  }
}

.login .el-card {
  //   width: 340px;
  display: flex;
  justify-content: center;
  padding: 30px;
  border-radius: 15px;
}

.loginfon img {
  height: 100%;
  object-position: center;
}

.loginfon {
  position: relative;
  z-index: 1;
}

.loginfon::before {
  // content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: 2;
  background-color: #224b693b;
}

.radus .el-form-item .el-form-item__content .el-input .el-input__inner {
  border-radius: 25px !important;
}

.form-box .el-input .el-input__inner {
  background: #475569 !important;
  border-color: #94a3b8 !important;
  color: #fff !important;

  &:hover,
  &:focus {
    background: #64748b !important;
  }
}

.form-box .login-button {
  width: 100%;
  background: #475569 !important;
  border-color: #94a3b8 !important;
  padding: 12px !important;
  border-radius: 25px;

  &:hover,
  &:active,
  &:focus {
    background-color: #94a3b8 !important;
    letter-spacing: 1px;
  }
}
</style>

<style lang="scss" scoped>
.login-layout {
  background: #1e293b;

  .loginfon {
    margin: 5vh 0vh 5vh 5vh;
    height: 90vh;
    border: 1px solid #475569 !important;
    border-radius: 1vh;
    background: #334155;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-box {
  border: 1px solid #475569 !important;
  border-radius: 1vh;
  padding: 24px 32px;
  background: #334155;

  &__title {
    color: #fff;
    margin-bottom: 20px;
  }
}
.flag .el-dropdown-link {
  padding: 10px 4px !important;
  border-radius: 10px;
  border-color: transparent;
}
</style>